import base64 from 'base-64';

export const useUrlObject = () => {
  const query = new URLSearchParams(window.location.search);
  const objectString = query.get('data');
  try {
    const object = JSON.parse(base64.decode(objectString));
    return object;
  } catch (e) {
    console.log('error occured:', e);
    return { markers: [], imageSourceUrl: '' };
  }
};

// export const useMarkers
