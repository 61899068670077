import { useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useUrlObject } from '../../hooks';
import { ReactComponent as Marker } from '../../imgs/map_marker.svg';
import {
  onPinClick,
  useDarkMode,
  DEFAULT_PIN_HEIGHT,
  DEFAULT_PIN_WIDTH,
  DEFAULT_PIN_COLOR,
  onImageLoad,
} from './Map.utils';
import Zoomer from './Zoomer';

const Map = ({ markers = [] }) => {
  useDarkMode();
  const data = useUrlObject();
  const imageRef = useRef(null);

  return (
    <TransformWrapper
      limitToBounds={false}
      initialScale={1}
      minScale={0.1}
      maxScale={1}
      pinch={{ step: 3 }}
      centerOnInit
      zoomAnimation={{
        disabled: true,
      }}
    >
      {({ state: { scale }, setTransform, ...transformProps }) => (
        <TransformComponent>
          <div className="content">
            <Zoomer zoomFunc={setTransform} imageRef={imageRef} />
            {markers.map(({ position: { x, y }, size = {}, tag, color, clickable }, index) => {
              const itemWidth = size.width || DEFAULT_PIN_WIDTH;
              const itemHeight = size.height || DEFAULT_PIN_HEIGHT;
              const itemColor = color || DEFAULT_PIN_COLOR
              const itemClickable = clickable !== undefined && typeof clickable === 'boolean' ? clickable : true
              return (
                <button
                  className="pin-button"
                  onClick={() => {
                    onPinClick(tag);
                  }}
                  key={`marker-item-${index}`}
                  style={{
                    left: `${x * 100}%`,
                    top: `${y * 100}%`,
                    transform: `scale(${1 / scale}) translate(0%,${
                      -scale * itemHeight - itemHeight
                    }%)`,
                    width: itemWidth,
                    height: itemHeight,
                  }}
                  disabled={!itemClickable}
                >
                  <Marker className="pin" alt="pin" color={itemColor}/>
                </button>
              );
            })}
            <img src={data.imageSourceUrl} alt="img" ref={imageRef} onLoad={() => onImageLoad()} />
          </div>
        </TransformComponent>
      )}
    </TransformWrapper>
  );
};

export default Map;
