/*eslint-disable no-undef*/
import { useState, useEffect } from 'react';

export const DEFAULT_PIN_WIDTH = 20;
export const DEFAULT_PIN_HEIGHT = 32;
export const DEFAULT_PIN_COLOR = "#00AEEF"

export const onPinClick = (tag) => {
  const tagObject = JSON.stringify(tag);

  if (
    typeof Android !== 'undefined' &&
    typeof Android.onMarkerClick !== 'undefined'
  ) {
    Android.onMarkerClick(tagObject);
    return;
  } else {
    console.log('Android.onMarkerClick callback died');
  }

  if (typeof onMarkerClick !== 'undefined') {
    onMarkerClick(tagObject);
    return;
  } else {
    console.log('onMarkerClick callback died');
  }

  if (
    typeof window.Android !== 'undefined' &&
    typeof window.Android.onMarkerClick !== 'undefined'
  ) {
    window.Android.onMarkerClick(tagObject);
    return;
  } else {
    console.log('window.Android.onMarkerClick callback died');
  }

  if (
    typeof window.webkit !== 'undefined' &&
    typeof window.webkit.messageHandlers !== 'undefined' &&
    typeof window.webkit.messageHandlers.onMarkerClick !== 'undefined' &&
    typeof window.webkit.messageHandlers.onMarkerClick.postMessage !==
      'undefined'
  ) {
    window.webkit.messageHandlers.onMarkerClick.postMessage(tagObject);
    return;
  } else {
    console.log('window.webkit.onMarkerClick callback died');
  }
};

export const onImageLoad = () => {
  const messageObject = JSON.stringify("spaceplanReady");
  if (
    typeof Android !== 'undefined' &&
    typeof Android.spaceplanReady !== 'undefined'
  ) {
    Android.spaceplanReady(messageObject);
    return;
  } else {
    console.log('Android.spaceplanReady callback died');
  }

  if (typeof spaceplanReady !== 'undefined') {
    spaceplanReady(messageObject);
    return;
  } else {
    console.log('spaceplanReady callback died');
  }

  if (
    typeof window.Android !== 'undefined' &&
    typeof window.Android.spaceplanReady !== 'undefined'
  ) {
    window.Android.spaceplanReady(messageObject);
    return;
  } else {
    console.log('window.Android.spaceplanReady callback died');
  }

  if (
    typeof window.webkit !== 'undefined' &&
    typeof window.webkit.messageHandlers !== 'undefined' &&
    typeof window.webkit.messageHandlers.spaceplanReady !== 'undefined' &&
    typeof window.webkit.messageHandlers.spaceplanReady.postMessage !==
      'undefined'
  ) {
    window.webkit.messageHandlers.spaceplanReady.postMessage(messageObject);
    return;
  } else {
    console.log('window.webkit.spaceplanReady callback died');
  }
};

export const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const setDarkModeListeners = document.addEventListener(
      'setDarkMode',
      function ({ detail: value }) {
        try {
          const darkModeValue = JSON.parse(value);
          setDarkMode(darkModeValue);
        } catch (e) {
          console.error('Failed to set dark mode', e);
        }
      }
    );

    return () => {
      document.removeEventListener('setDarkMode', setDarkModeListeners);
    };
  }, []);

  const container = document.getElementById('root');
  useEffect(() => {
    container.style.background = darkMode ? '#121212' : '#fff';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);
};
