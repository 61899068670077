import { useEffect, useState } from 'react';

export const useMarkers = () => {
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const setMarkersListener = document.addEventListener(
      'setMarkers',
      function ({ detail: jsonMarkers }) {
        try {
          const markers = JSON.parse(jsonMarkers);
          setMarkers(markers);
        } catch (e) {
          console.error('Failed to set markers', e);
        }
      }
    );

    return () => {
      document.removeEventListener('setMarkers', setMarkersListener);
    };
  }, []);

  return markers;
};
